
import { defineComponent, onBeforeUnmount, watch } from 'vue';

import { ElMessage } from 'element-plus';
import { routerLoading } from '@/layout/messageCenter/routerRef';
import { useRouter } from 'vue-router';
import { getUser } from '@/shared/userinfo';

export default defineComponent({
  name: 'RouterLoading',
  setup() {
    let timeout: any = setInterval(() => {
      ElMessage.info('当前网络较慢，已经重定向');
      getUser();
    }, 8000);

    const router = useRouter();

    watch(routerLoading, (nn) => {
      let repath = window.location.search;
      if (repath.includes('/router-loading?redirect=')) {
        repath = repath.slice(25);
      } else if (repath.includes('?redirect=')) {
        repath = repath.slice(10);
      } else {
        repath = window.location.pathname + repath;
      }
      if (!nn) {
        router.push(repath);
      }
    });

    onBeforeUnmount(() => {
      clearInterval(timeout);
      timeout = null;
    });
  },
});
